import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Testimonials = makeShortcode("Testimonials");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hello. It's really great to meet you!`}</h1>
    <p>{`We got your message first class. Thank you for contacting us. Foxtrot Aviation Services will have someone from our staff follow-up with you soon.`}</p>
    <div className='w-full-breakout mt-24 -mb-24'>
  <Testimonials tag='home' mdxType="Testimonials" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      